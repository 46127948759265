export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyD6nJAMpJ9epQ_T5qZT2zdRQqZ2Hy3niHY",
    authDomain: "dev-subashree-publishers.firebaseapp.com",
    databaseURL: "https://dev-subashree-publishers-default-rtdb.firebaseio.com",
    projectId: "dev-subashree-publishers",
    storageBucket: "dev-subashree-publishers.appspot.com",
    messagingSenderId: "316246821567",
    appId: "1:316246821567:web:744a81856af5effd55940a",
    measurementId: "G-DEH26Z8GM8",
  },
};
